<template>
  <div>
    <b-loading is-full-page :active="carregando" can-cancel></b-loading>

    <AppTitulo titulo="Faturas a Processar" />
    <section class="section">
      <div class="container is-fullhd">
        <!-- <FiltroInvoices /> -->

        <div class="panel">
          <template>
            <div>
              <div class="tile is-ancestor">
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <p class="subtitle">Gerar Fatura</p>
                    <p class="title text-right">
                      {{ formatarValor(this.summary.semFatura, 2) }}
                    </p>
                  </article>
                </div>
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <p class="subtitle">Emitir Nota</p>
                    <p class="title text-right">{{ formatarValor(this.summary.semNota, 2) }}</p>
                  </article>
                </div>
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <p class="subtitle">Emitir Boleto</p>
                    <p class="title text-right">{{ formatarValor(this.summary.semBoleto, 2) }}</p>
                  </article>
                </div>
                <div class="tile is-parent">
                  <article class="tile is-child box">
                    <p class="subtitle">Notificar Cliente</p>
                    <p class="title text-right">{{ formatarValor(this.summary.semEmail, 2) }}</p>
                  </article>
                </div>
              </div>
            </div>
          </template>
          <br />
          <section>
            <b-tabs
              expanded
              ref="tabs"
              type="is-toggle"
              destroy-on-hide
              :animated="false"
              v-model="activeTab"
            >
              <b-tab-item :value="'PENDENTE'" label="A Faturar">
                <InvoiceTable :activeTab="activeTab"></InvoiceTable>
              </b-tab-item>

              <b-tab-item :value="'FATURA_EMITIDA'" label="Emitir Nota">
                <InvoiceTable :activeTab="activeTab"></InvoiceTable>
              </b-tab-item>
              <b-tab-item :value="'NOTA_EMITIDA'" label="Gerar Boletos">
                <InvoiceTable :activeTab="activeTab"></InvoiceTable>
              </b-tab-item>
              <b-tab-item :value="'BOLETO_EMITIDO'" label="Enviar Cobrança">
                <InvoiceTable :activeTab="activeTab"></InvoiceTable>
              </b-tab-item>
            </b-tabs>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { funcoes } from '@/utils/enumerados';
import AppTitulo from '@/components/AppTitulo';
import InvoiceTable from '@/components/InvoiceTable';
import { formatarValor, formatarValorSemPrefixo, formatarDataSemHorario } from '@/utils/format';

export default {
  name: 'Financial',
  components: {
    AppTitulo,
    InvoiceTable,
  },
  data() {
    const statusOptions = [
      { id: 'PENDENTE', description: 'Gerar Fatura' },
      { id: 'FATURA_EMITIDA', description: 'Emitir Nota' },
      { id: 'NOTA_EMITIDA', description: 'Emitir Boleto' },
      { id: 'BOLETO_EMITIDO', description: 'Notificar Cliente' },
    ];
    return {
      activeTab: 'PENDENTE',
      selected: [],
      onlySelectedOnes: false,
      showOptions: true,
      funcoes,
      formatarValor,
      formatarValorSemPrefixo,
      formatarDataSemHorario,
      carregando: false,
      statusOptions,
    };
  },
  computed: {
    ...mapState('invoices', ['summary']),
  },
};
</script>

<style lang="scss">
.table-item {
  background-color: #fafafa;
}

.is-centered {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.field.is-grouped .field {
  flex-shrink: 1;
}

.cor-vermelho {
  color: red;
}

.cor-amarelo {
  color: yellow;
}

.cor-verde {
  color: green;
}

.custom-field {
  width: 200px;
  align-items: end;
}

.text-right {
  text-align: right;
}

.right-aligned-field {
  width: 300px;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px;
}
</style>
